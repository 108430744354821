import React from 'react';
import './Nav.css';

const Nav = () => {
  return (
    <div id="navBar">
        <p id='navItem'>
            <a href='/'>
              Home
            </a>
        </p>
        <p id='navItem'>
        <a href='/services'>
            Our Services</a>
        </p>
        <p id='navItem'>
        <a href='/contact-us'>
            Contact Us</a>
        </p>
    </div>
  )
}

export default Nav