import React from 'react';
import './HeroSection.css';


const HeroSection = () => {
  return (
    <div id="heromain">
                <h1 style={{fontSize:"5vw", fontStyle:"italic", color:"#253551", margin:0, padding:0}}>
                    Empowering Online
                </h1>
                <h1 style={{fontSize:"5vw", fontStyle:"italic", color:"#253551", margin:0, padding:0}}>
                    presence
                </h1>
                <h3 style={{fontSize:"2.2vw", fontStyle:"italic", color:"#3673A0"}}>
                    Web, Digital, SEO Mastery
                </h3>
                <p>
                    <a href='/contact-us' style={{fontSize:"2.5vw", textDecorationLine:'underline', fontStyle:"italic", color:"#253551", opacity:0.9}}>
                        Schedule a Consultation Today.
                    </a>
                </p>
    </div>
  )
}

export default HeroSection