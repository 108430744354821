import React from 'react';
import './ServicesHeroSection.css';

const ServicesHeroSection = () => {
  return (
    <div id="servicesmain">
        <h1 style={{fontSize:"5vw", fontStyle:"italic", color:"#253551", margin:0, padding:0}}>
            Services
        </h1>
</div>
  )
}

export default ServicesHeroSection