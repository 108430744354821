import React from 'react';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

const ServicesBody = () => {
  return (
    <div style={{backgroundColor:"white", margin:0,padding:"5vh", display:"flex", justifyContent:"center"}}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Item>
                    <h3 style={{fontSize:"3.5vw",fontStyle:"italic",color:"#253551", margin:0,padding:0}}>
                    Search Engine Optimization
                    </h3>
                </Item>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Item>
                        <p style={{fontSize:"1.6vw",color:"#253551", margin:0,padding:0}}>
                        Are you looking to make your local business stand out in the vast online landscape? 
                        At Search Crafters, <mark>we have the perfect solution for you.</mark> 
                        Our team of experts employs advanced proprietary techniques that are specifically 
                        designed to catapult your business to the forefront of your industry.
                        </p>
                </Item>
            </Grid>
            
            <Grid item xs={12} sm={6}>
                <Item>
                    <h3 style={{fontSize:"3.5vw",fontStyle:"italic",color:"#253551", margin:0,padding:0}}>
                    Consultation
                    </h3>
                </Item>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Item>
                        <p style={{fontSize:"1.6vw",color:"#253551", margin:0,padding:0}}>
                        Regardless of whether your marketing team comprises a solitary specialist or 
                        an extensive group, we hold the knowledge and skills to enable them and 
                        make the most of your digital resources and possibilities. 
                        Our proficiency lies in equipping your team to fully exploit the 
                        potential of digital marketing.
                        </p>
                </Item>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Item>
                    <h3 style={{fontSize:"3.5vw",fontStyle:"italic",color:"#253551", margin:0,padding:0}}>
                    Social Media Marketing
                    </h3>
                </Item>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Item>
                        <p style={{fontSize:"1.6vw",color:"#253551", margin:0,padding:0}}>
                        Our social media marketing company specializes in creating and implementing 
                        effective strategies to boost brand awareness, engage audiences, 
                        and drive measurable results through various social media platforms.
                        </p>
                </Item>
            </Grid>
        </Grid>
    </div>
  )
}

export default ServicesBody