import React from 'react';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div style={{backgroundColor:"white", margin:0,padding:"5vh"}}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <Item>
                    <h3 style={{fontSize:"3.5vw",fontStyle:"italic",color:"#253551", marginLeft:"5vw", margin:0,padding:0}}>
                        Build your brand's success
                    </h3>
                </Item>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Item>
                        <p style={{fontSize:"1.6vw",color:"#253551", margin:0,padding:0}}>
                        We specialize in transforming your digital presence and skyrocketing your online success. 
                        We understand the ever-changing landscape of search engine optimization and have the expertise 
                        to drive targeted traffic, increase your organic rankings, and maximize your website's visibility. 
                        Our team of skilled professionals is dedicated to crafting <mark>customized SEO strategies </mark> tailored to 
                        your unique business goals, ensuring maximum ROI and sustainable growth. From comprehensive website 
                        audits and keyword research to on-page optimization and authoritative link building, we leverage 
                        cutting-edge techniques and industry best practices to propel your website to the top of search engine results. 
                        <b>Trust us</b> to deliver tangible results, enhance your brand's online reputation, and outshine your competitors. 
                        </p>
                </Item>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Item>
                <>
                <h3 style={{fontSize:"1.8vw",color:"#253551", margin:0,padding:0}}>
                Analytical Approach:
                </h3>
                    <p style={{fontSize:"1.6vw",color:"#253551"}}>
                    Our service includes a comprehensive consult to help identify gaps and opportunities
                     and results in a comprehensive report that includes a project plan with timelines and a cost analysis. 
                     Our individualized plans are made up of quality services that will help you get there quickly and smoothly. 
                    </p>
                <h3 style={{fontSize:"1.8vw",color:"#253551", margin:0,padding:0}}>
                    Industries We Work With:
                </h3>
                    <p style={{fontSize:"1.6vw",color:"#253551"}}>
                        <ul>
                            <li>Healthcare</li>
                            <li>Financial/Banking</li>
                            <li>E-Commerce</li>
                            <li>Restaurant</li>
                        </ul>
                    </p>
                </>
                </Item>
            </Grid>
        </Grid>
    </div>
  )
}

export default AboutUs