import React from 'react';
import ContactHeroSection from './components/contactus/ContactHeroSection';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import ContactLocation from './components/contactus/ContactLocation';
import ContactForm from './components/contactus/ContactForm';

const ContactUs = () => {
  return (
    <>
    <ContactHeroSection />
    <div style={{padding:"3vw", backgroundColor:"white"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Item>
                      <ContactLocation />
                    </Item>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Item>
                      <ContactForm />
                    </Item>
                </Grid>
            </Grid>
        </div>

    </>

  )
}

export default ContactUs