import React from 'react';
import WAQR from '../../img/whatsappQR.jpg';

const ContactLocation = () => {
  return (
    <div style={{display:"flex",flexDirection:"column", alignItems:"center"}}>
        <h3 style={{fontSize:"3.5vw",margin:0}}>
        Location
        </h3>
        <p style={{fontSize:"1.6vw",color:"#253551", margin:0,padding:0, }}>
        Saskatoon, SK, Canada.
        </p>
        <img src={WAQR} alt="Whatsapp QR" style={{height:"200px",width:"auto", overflow: "hidden", margin:0}}/>        
        <p style={{fontSize:"1.6vw",color:"#253551", margin:0,padding:0, }}>
        +1 (403) 929-7428 
        </p>
        <p style={{fontSize:"1.6vw",color:"#253551", margin:0, padding:0, textDecorationLine:'underline', fontStyle:"italic"}}>
            <a href="mailto:searchcraftersseo@gmail.com" style={{color:"#253551", opacity:0.9}}>
              searchcraftersseo@gmail.com
              </a>
        </p>

    </div>
  )
}

export default ContactLocation