import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import './ContactForm.css';
import { useState } from 'react';


const ContactForm = () => {
    const [succeed, setSucceed] = useState(false);
      const form = useRef();
      const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm("service_n5z5crn","template_hffefzj", form.current, '62ifOZxc1ZPNQCXzu')
          .then((result) => {
              console.log(result.text)
              
                setSucceed(true);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div id="contactformdiv" style={{paddingTop:"50px",paddingBottom:"50px", paddingLeft:"30px",margin:0, height:"100%",width:"100%",}}>
        {succeed ? ( 
            <div>
            <h3 style={{fontSize:"3.5vw",display:'flex',justifyContent:"center",margin:0}}>
                Thank you for contacting us!
            </h3>
            <p style={{fontSize:"1.6vw",color:"#253551", margin:0,padding:0, textAlign:"center"}}>
            We will get back to you as soon as possible.
            </p>
            </div>
                 ) : (
                    <Grid container spacing={2} >
                        <form ref={form} onSubmit={sendEmail}>
                        <Grid item xs={12} sm={12}>
                            <Item>
                            <label>First Name</label>
                            </Item>
                            <Item>
                            <input type="text" name="firstname" />
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Item>
                            <label>Last Name</label>
                            </Item>
                            <Item>
                            <input type="text" name="lastname" />
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Item>
                            <label>Email</label>
                            </Item>
                            <Item>
                            <input type="email" name="email" />
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Item>
                            <label>Message</label>
                            </Item>
                            <Item>
                            <textarea type="text" name="message"/>
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Item>
                                <p style={{fontSize:"1.3vw", marginBottom:0, paddingBottom:0}}>I am interested in ...</p>
                            <input type="checkbox" name="seo" value="SEO" />
                            <label>SEO</label>
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Item>
                            <input type="checkbox" name="webdesign" value="Web Design" />
                            <label>Web Design</label>
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Item>
                            <input type="checkbox" name="graphic" value="Graphic Design" />
                            <label>Graphic Design</label>
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Item>
                            <input type="checkbox" name="socialmedia" value="Social Media" />
                            <label>Social Media</label>
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Item>
                            <input type="checkbox" name="others" value="Others" />
                            <label>Others</label>
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Item>
                            <input type="submit" value="Send" />
                            <input type="reset" value="Clear" />
                            </Item>
                        </Grid>
                        </form>
                    </Grid>)}
    </div>
  )
}

export default ContactForm