import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';

import AboutUs from './components/AboutUs';
import Headers from './components/Headers';
import HeroSection from './components/HeroSection';
import HomeServices from './components/HomeServices';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Footer2 from './components/Footer2';
import Home from './Home';
import ContactUs from './ContactUs';
import Services from './Services';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Error404 from './components/Error404';


const router = createBrowserRouter([
  {
    path: "/",
    element:<Home />,
    errorElement: <Error404 />
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Headers />
      <Nav />
          <RouterProvider router={router}/>
      <Footer />
      <Footer2 />
  </React.StrictMode>
);