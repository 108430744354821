import React from 'react'

const Error404 = () => {
  return (
    <div style={{display:'flex',flexDirection:"column",justifyContent:"center",alignItems:"center",height:"50vh"}}>
        <h1 style={{fontSize:"5vw",display:'flex',justifyContent:"center",margin:0, color:"#253551"}}>
            Error 404.
        </h1>
        <h3 style={{fontSize:"3vw",display:'flex',justifyContent:"center",margin:0,color:"#253551"}}>
            Ops! Page Not Found.
        </h3>
    </div>
  )
}

export default Error404