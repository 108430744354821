import React from 'react';
import '../App.css';

const Headers = () => {
  return (
    <div>
        <h1 id='logoText' style={{margin: 0, padding:0,paddingTop:"2vh", display:"flex", justifyContent:"center"}}>
            Search Crafters
        </h1>
    </div>
  )
}

export default Headers