import './App.css';
import AboutUs from './components/AboutUs';
import HeroSection from './components/HeroSection';
import HomeServices from './components/HomeServices';

function Home() {
  return (
    <>
    <HeroSection />
    <AboutUs />
    <HomeServices />
    </>
  );
}

export default Home;
