import React from 'react';
import './ContactHeroSection.css';

const ContactHeroSection = () => {
  return (
    <div id="contactmain">
        <h1 style={{fontSize:"5vw", fontStyle:"italic", color:"#253551", margin:0, padding:0}}>
            Contact Us
        </h1>
</div>
  )
}

export default ContactHeroSection