import React from 'react';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import './Footer.css';

const Footer = () => {
    // Eventhandler to redirect page to /contact-us when button is clicked
    const handleClick = () => {
        window.location.href = '/contact-us';
    }
    return (
    <div style={{padding:"3vw", backgroundColor:"#2A2829"}}>
    <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
            <Item>
                <h3 style={{fontSize:"3.5vw",display:'flex',justifyContent:"center",margin:0, color:"white"}}>
                    Let's Talk !
                </h3>
            </Item>
        </Grid>
        <Grid item xs={12} sm={12}>
            <Item>
                <h3 style={{fontSize:"2vw",display:'flex',fontStyle:"italic",justifyContent:"center",margin:0, color:"white"}}>
                    Your first consultation is free.
                </h3>
            </Item>
        </Grid>
        <Grid item xs={12} sm={12}>
            <Item style={{display:'flex',fontStyle:"italic",justifyContent:"center",fontFamily:'Cormorant Garamond'}}>
                    <button type='button' onClick={handleClick} className="custom-button">
                        Schedule a Consultation
                    </button>

            </Item>
        </Grid>
    </Grid>
    </div>
  )
}

export default Footer