import React from 'react';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import Consulting from '../img/consulting.jpg';
import SEO from '../img/seo.jpg';
import SM from '../img/socialmedia.jpg';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const MyCard = ({ imageUrl, title, description }) => {
    return (
      <Card >
        <CardMedia
          component="img"
            height="300"
          image={imageUrl}
          alt="Card Image"
        />
        {/* <CardHeader title={title} component='div' sx={{color:"#253551",textAlign:"center", marginBottom:0,paddingBottom:0, fontFamily:'Cormorant Garamond !important'}} /> */}
        <CardContent>
          <Typography sx={{fontSize:"2vw",color:"#253551", textAlign:"center", fontFamily:'Cormorant Garamond !important'}}>
            {title}
          </Typography>
          <Typography sx={{fontSize:"1.2vw",color:"#253551", textAlign:"center", fontFamily:'Cormorant Garamond !important'}}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    );
  };
  
const HomeServices = () => {
  return (
    <div style={{padding:"3vw"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Item>
                        <h3 style={{fontSize:"3.5vw",display:'flex',justifyContent:"center",margin:0}}>
                            Our Services
                        </h3>
                    </Item>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Item>
                        {/* <img src={SEO} alt="SEO" style={{width:"100%",maxHeight:"450px",overflow: "hidden", margin:0}}/>
                        <h3 style={{fontSize:"2.5vw",display:'flex',justifyContent:"center"}}>
                        Search Engine Optimization
                        </h3>
                        <p style={{fontSize:"1.6vw",color:"#253551", margin:0,padding:0, textAlign:"center"}}>
                            Are you looking to make your local business stand out in the vast online landscape? 
                        At Search Crafters, we have the perfect solution for you. Our team of experts employs 
                        advanced proprietary techniques that are specifically designed to catapult your 
                        business to the forefront of your industry.
                        </p> */}
                        <MyCard 
                            imageUrl={SEO}
                            title="Search Engine Optimization"
                            description="Are you looking to make your local business stand out in the vast online landscape?
                            At Search Crafters, we have the perfect solution for you. Our team of experts employs
                            advanced proprietary techniques that are specifically designed to catapult your
                            business to the forefront of your industry."
                        />
                    </Item>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Item>
                        {/* <img src={Consulting} alt="Consulting" style={{width:"100%",maxHeight:"450px",overflow: "hidden", margin:0}}/>
                        <h3 style={{fontSize:"2.5vw",display:'flex',justifyContent:"center", }}>
                        Consultation
                        </h3>
                        <p style={{fontSize:"1.6vw",color:"#253551", margin:0,padding:0,textAlign:"center"}}>
                        Regardless of whether your marketing team comprises a solitary specialist or 
                        an extensive group, we hold the knowledge and skills to enable them and make 
                        the most of your digital resources and possibilities.  Our proficiency lies 
                        in equipping your team to fully exploit the potential of digital marketing
                        </p> */}
                        <MyCard 
                            imageUrl={Consulting}
                            title="Consultation"
                            description="Regardless of whether your marketing team comprises a solitary specialist or
                            an extensive group, we hold the knowledge and skills to enable them and make
                            the most of your digital resources and possibilities.  Our proficiency lies
                            in equipping your team to fully exploit the potential of digital marketing"
                        />
                    </Item>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Item>
                        {/* <img src={SM} alt="Social Media" style={{width:"100%", maxHeight:"450px",overflow: "hidden", margin:0}}/>
                        <h3 style={{fontSize:"2.5vw",display:'flex',justifyContent:"center"}}>
                        Social Media Marketing
                        </h3>
                        <p style={{fontSize:"1.6vw",color:"#253551", margin:0,padding:0,textAlign:"center"}}>
                        Our social media marketing company specializes in creating and implementing 
                        effective strategies to boost brand awareness, engage audiences, and drive 
                        measurable results through various social media platforms </p> */}
                        <MyCard
                            imageUrl={SM}
                            title="Social Media Marketing"
                            description="Our social media marketing company specializes in creating and implementing
                            effective strategies to boost brand awareness, engage audiences, and drive
                            measurable results through various social media platforms"
                        />
                    </Item>
                </Grid>
            </Grid>
    </div>
  )
}

export default HomeServices