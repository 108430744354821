import React from 'react';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

const Footer2 = () => {
  return (
    <div style={{padding:"3vw", backgroundColor:"#000000"}}>
    <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
            <Item>
                <h3 style={{fontSize:"2vw",display:'flex',justifyContent:"center",color:"white"}}>
                    Location
                </h3>
                <p style={{fontSize:"1.5vw",display:'flex',justifyContent:"center", color:"white"}}>
                    Saskatoon, SK, Canada.
                </p>
            </Item>
        </Grid>
        <Grid item xs={12} sm={4}>
            <Item>
                <h3 style={{fontSize:"2vw",display:'flex',justifyContent:"center",color:"white"}}>
                    Contact
                </h3>
                <p style={{fontSize:"1.5vw",display:'flex',justifyContent:"center", color:"white"}}>
                    +1 (403) 929-7428 
                </p>
                <p style={{fontSize:"1.5vw",display:'flex',justifyContent:"center", color:"white",marginTop:0,paddingTop:0}}>

                    searchcrafterseo@gmail.com
                </p>
            </Item>
        </Grid>
    </Grid>
    </div>
  )
}

export default Footer2