import React from 'react';
import ServicesHeroSection from './components/services/ServicesHeroSection';
import ServicesBody from './components/services/ServicesBody';

const Services = () => {
  return (
    <>
    <ServicesHeroSection />
    <ServicesBody />
    </>
  )
}

export default Services